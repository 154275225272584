import React, { useState, useEffect } from "react";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { Helmet } from "react-helmet-async";
import { MediaQuery } from "@outplayed/responsive";
import MSTextBox from "./MSTextBox";
import MultiSearchResultsContainer from "./MultiSearchResultsContainer";
import { useQuery } from "@apollo/client";
import { GET_MULTISEARCH } from "@ugg/shared/api/graphql/queries";
import { getUrl } from "@outplayed/riot-assets";

function getRiotId(value, delimeter = "#") {
  let [inputRiotUserName, inputRiotTagLine] = (value || "").toLowerCase().split(delimeter || "#");
  inputRiotUserName = inputRiotUserName.trim();
  return {
    riotUserName: inputRiotUserName,
    riotTagLine: inputRiotTagLine || "",
  };
}

function setSearchState(summoners, region) {
  return {
    riotUserName: (summoners || "").split(",").map((id) => getRiotId(id, "-").riotUserName),
    riotTagLine: (summoners || "").split(",").map((id) => getRiotId(id, "-").riotTagLine),
    regionId: region,
  };
}

const MultiSearchMain = (props) => {
  const { summoners, region } = useQueryString();
  const [multiSearchVariables, setMultiSearchVariables] = useState(setSearchState(summoners, region));

  useEffect(() => {
    setMultiSearchVariables(setSearchState(summoners, region));
  }, [JSON.stringify(summoners), region]);

  const queryResponse = useQuery(GET_MULTISEARCH, {
    variables: {
      riotUserName: multiSearchVariables.riotUserName,
      riotTagLine: multiSearchVariables.riotTagLine,
      regionId: multiSearchVariables.riotUserName.map((el) => multiSearchVariables.regionId),
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    ssr: false,
  });
  const { loading, data, error } = queryResponse;

  const content = !summoners ? (
    <React.Fragment>
      <MediaQuery min="TABLET" max="DESKTOP_LARGE">
        <img className="multisearch-image" src={getUrl("lol/art/MultisearchCTA.jpg")} />
      </MediaQuery>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
        <img className="multisearch-image small" src={getUrl("lol/art/MultisearchCTAsmall.jpg")} />
      </MediaQuery>
    </React.Fragment>
  ) : (
    <MultiSearchResultsContainer regionId={multiSearchVariables.regionId} loading={loading} data={data} error={error} />
  );

  return (
    <div className="content-side-padding">
      <div className="multisearch-main">
        <Helmet>
          <title>U GG: Multisearch LoL Profile Stats for Clash, Ranked All Regions</title>
          <meta
            name="description"
            content="Only U.GG Profiles have LP per game. Prep for Ranked or Clash by viewing multiple profiles at once. Includes main champions, role, and tags."
          />
        </Helmet>
        <MSTextBox multiSearchVariables={multiSearchVariables} />
        {content}
      </div>
    </div>
  );
};

export default MultiSearchMain;
